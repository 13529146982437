import {
  AstraFiCard,
  BankAccountStatus,
  BillingScheme,
  Network,
  PaymentStatus,
  PaymentType,
  PayoutNetwork,
  PayoutStatus,
  PayoutType,
  PriceType,
  ROLES,
  SphereEventNames,
  SubscriptionStatus,
  WidenEnum,
} from '@spherelabs/common';

export const getHumanReadableSubscriptionStatus = (
  status: SubscriptionStatus,
) => {
  switch (status) {
    case SubscriptionStatus.PENDING:
      return 'pending';
    case SubscriptionStatus.INCOMPLETE:
      return 'incomplete';
    case SubscriptionStatus.ACTIVE:
      return 'active';
    case SubscriptionStatus.PAST_DUE:
      return 'past due';
    case SubscriptionStatus.CANCELED:
      return 'cancelled';
    default:
      return 'unspecified';
  }
};

export const getHumanReadableMembershipRole = (role: ROLES) => {
  switch (role) {
    case 'ADMIN':
      return 'Admin';
    case 'VIEW_ONLY':
      return 'Read Only';
    default:
      return 'Unspecified';
  }
};

export const getHumanReadablePriceType = (type: PriceType): string => {
  switch (type) {
    case PriceType.ONE_TIME:
      return 'one time';
    case PriceType.RECURRING:
      return 'recurring';
    default:
      return 'unspecified';
  }
};

export const getHumanReadablePaymentStatus = (
  status: PaymentStatus,
): string => {
  switch (status) {
    case PaymentStatus.SUCCEEDED:
      return 'paid';
    case PaymentStatus.REFUNDED:
      return 'refunded';
    case PaymentStatus.FAILED:
      return 'failed';
    case PaymentStatus.PENDING:
      return 'pending';
    default:
      return '';
  }
};

export const getHumanReadablePayoutStatus = (status: PayoutStatus): string => {
  switch (status) {
    case PayoutStatus.SUCCEEDED:
      return 'succeeded';
    case PayoutStatus.CANCELLED:
      return 'cancelled';
    case PayoutStatus.FAILED:
    case PayoutStatus.FAILED_PRECONDITION:
      return 'failed';
    case PayoutStatus.PENDING:
      return 'initiated';
    default:
      return 'processing';
  }
};

export const getHumanReadableBankAccountStatus = (
  status: BankAccountStatus,
): string => {
  switch (status) {
    case BankAccountStatus.Active:
      return 'active';
    case BankAccountStatus.Pending:
      return 'pending';
    case BankAccountStatus.Invalid:
      return 'invalid';
    default:
      return '-';
  }
};

export const getHumanReadableCardType = (
  cardType: AstraFiCard.Type,
): string => {
  switch (cardType) {
    case AstraFiCard.Type.DEBIT:
      return 'debit';
    case AstraFiCard.Type.CREDIT:
      return 'credit';

    default:
      cardType satisfies never;
      return 'unknown';
  }
};

export const getHumanReadableCardStatus = (
  cardStatus: AstraFiCard.Status,
): string => {
  switch (cardStatus) {
    case AstraFiCard.Status.APPROVED:
      return 'approved';
    case AstraFiCard.Status.FLAGGED:
      return 'flagged';
    case AstraFiCard.Status.REJECTED:
      return 'rejected';
    case AstraFiCard.Status.PENDING:
      return 'pending';
    case AstraFiCard.Status.REMOVED:
      return 'removed';

    default:
      cardStatus satisfies never;
      return 'unknown';
  }
};

export const getHumanReadablePayoutType = (type: PayoutType): string => {
  switch (type) {
    case PayoutType.OnRamp:
      return 'On-Ramp';
    case PayoutType.OffRamp:
      return 'Off-Ramp';
    case PayoutType.OnChain:
      return 'On-Chain';
    default:
      return type;
  }
};

export const getHumanReadableNetwork = (
  network: WidenEnum<Network | PayoutNetwork>,
): string => {
  switch (network) {
    case Network.LOCALHOST:
      return 'Localhost';
    case Network.SOL:
    case PayoutNetwork.SOL:
      return 'Solana';
    case Network.ETH_MAINNET:
    case PayoutNetwork.ETH:
      return 'Ethereum';
    case Network.POLYGON_MAINNET:
      return 'Polygon';
    case Network.GNOSIS_MAINNET:
      return 'Gnosis';
    case Network.STRIPE:
      return 'Stripe';
    case Network.OPTIMISM_MAINNET:
      return 'Optimism';
    case Network.BSC_MAINNET:
      return 'BNB Smart Chain';
    case Network.WIRE:
    case PayoutNetwork.WIRE:
      return 'Wire';
    case Network.SEPOLIA_TESTNET:
      return 'Sepolia';
    case Network.ACH:
    case PayoutNetwork.ACH:
      return 'ACH';
    case Network.ACH_PUSH:
    case PayoutNetwork.ACH_PUSH:
      return 'ACH Push';
    case PayoutNetwork.ACH_PULL:
    case Network.ACH_PULL:
      return 'ACH Pull';
    case Network.ACH_SAME_DAY:
    case PayoutNetwork.ACH_SAME_DAY:
      return 'Same Day ACH';
    case PayoutNetwork.SEPA:
    case Network.SEPA:
      return 'SEPA';
    case Network.BITCOIN:
      return 'Bitcoin';
    case Network.ARBITRUM_MAINNET:
    case PayoutNetwork.ARBITRUM:
      return 'Arbitrum';
    case PayoutNetwork.OPTIMISM:
      return 'Optimism';
    case PayoutNetwork.POLYGON:
      return 'Polygon';
    default:
      // return network satisfies never;
      return '';
  }
};

export const getHumanReadablePaymentType = (type: PaymentType) => {
  switch (type) {
    case PaymentType.SUBSCRIPTION:
      return 'subscription';
    case PaymentType.PAYMENT_LINK:
      return 'one time';
    default:
      return '-';
  }
};

export const getHumanReadablePaymentEventMessage = (
  eventName: string,
): string => {
  switch (eventName) {
    // one time payment
    case SphereEventNames.PENDING_PAYMENT:
      return 'Payment started';
    case SphereEventNames.SUCCESSFUL_PAYMENT:
      return 'Payment succeeded';
    case SphereEventNames.FAILED_PAYMENT:
      return 'Payment failed';
    case SphereEventNames.CANCELLED_PAYMENT:
      return 'Payment canceled';
    case SphereEventNames.INCOMPLETE_PAYMENT:
      return 'Payment incomplete';
    // recurring payment
    case SphereEventNames.CREATE_SUBSCRIPTION:
      return 'Subscription created.';
    case SphereEventNames.UPDATE_SUBSCRIPTION:
      return 'Subscription updated.';
    case SphereEventNames.PAST_DUE_SUBSCRIPTION:
      return 'Subscription past due.';
    case SphereEventNames.BILL_SUBSCRIPTION:
      return 'Subscription billed.';
    case SphereEventNames.APPROVE_SUBSCRIPTION:
      return 'Subscription funding approved.';
    case SphereEventNames.RETRY_BILL_SUBSCRIPTION:
      return 'Subscription billing retry.';
    case SphereEventNames.CANCEL_SUBSCRIPTION:
      return 'Subscription cancelled.';
    case SphereEventNames.LOW_BALANCE_DELEGATED_SUBSCRIPTION:
      return 'Subscription low delegated balance.';
    case SphereEventNames.LOW_BALANCE_ESCROWED_SUBSCRIPTION:
      return 'Subscription low escrowed balance.';
    case SphereEventNames.INSUFFICIENT_DELEGATED_APPROVED_BALANCE_SUBSCRIPTION:
      return 'Subscription has insufficient delegate approved amount.';
    case SphereEventNames.INSUFFICIENT_ESCROWED_BALANCE_SUBSCRIPTION:
      return 'Subscription has insufficient escrowed amount.';
    case SphereEventNames.INSUFFICIENT_DELEGATED_BALANCE_SUBSCRIPTION:
      return 'Subscription has insufficient funds in the customer’s wallet.';
    case SphereEventNames.REDELEGATED_SUBSCRIPTION:
      return 'Subscription has re-delegated their funding account. ';
    default:
      return '--';
  }
};

export const getHumanReadableBillingScheme = (
  scheme: BillingScheme,
): string => {
  switch (scheme) {
    case BillingScheme.PER_UNIT:
      return 'per unit';
    case BillingScheme.TIERED:
      return 'tiered';
    default:
      return '';
  }
};

export const getHumanReadableHttpStatus = (status: number): string => {
  switch (status) {
    case 100:
      return 'Continue';
    case 101:
      return 'Switching Protocols';
    case 200:
      return 'OK';
    case 201:
      return 'Created';
    case 202:
      return 'Accepted';
    case 203:
      return 'Non-Authoritative Information';
    case 204:
      return 'No Content';
    case 205:
      return 'Reset Content';
    case 206:
      return 'Partial Content';
    case 300:
      return 'Multiple Choices';
    case 302:
      return 'Found';
    case 303:
      return 'See Other';
    case 304:
      return 'Not Modified';
    case 305:
      return 'Use Proxy';
    case 400:
      return 'Bad Request';
    case 401:
      return 'Unauthorized';
    case 402:
      return 'Payment Required';
    case 403:
      return 'Forbidden';
    case 404:
      return 'Not Found';
    case 405:
      return 'Method Not Allowed';
    case 406:
      return 'Not Acceptable';
    case 407:
      return 'Proxy Authentication Required';
    case 408:
      return 'Request Timeout';
    case 409:
      return 'Conflict';
    case 410:
      return 'Gone';
    case 411:
      return 'Length Required';
    case 412:
      return 'Precondition Failed';
    case 413:
      return 'Request Entity Too Large';
    case 414:
      return 'Request-URI Too Long';
    case 415:
      return 'Unsupported Media Type';
    case 416:
      return 'Requested Range Not Satisfiable';
    case 417:
      return 'Expectation Failed';
    case 500:
      return 'Internal Server Error';
    case 501:
      return 'Not Implemented';
    case 502:
      return 'Bad Gateway';
    case 503:
      return 'Service Unavailable';
    case 504:
      return 'Gateway Timeout';
    case 505:
      return 'HTTP Version Not Supported';
    default:
      return '';
  }
};
